import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as BarChart } from "../../../assets/icons/bar-chart.svg";
import { ReactComponent as MonitorIcon } from "../../../assets/icons/monitor.svg";
import { ReactComponent as CheckCircle } from "../../../assets/icons/check-circle.svg";
import { ReactComponent as UserCheck } from "../../../assets/icons/user-check.svg";
import { ReactComponent as Smile } from "../../../assets/icons/smile.svg";
import { ReactComponent as ThreeLayers } from "../../../assets/icons/3-layers.svg";
import { ReactComponent as PlayCircle } from "../../../assets/icons/play-circle-red.svg";
import { ReactComponent as MessageCircle } from "../../../assets/icons/message-circle.svg";
import { ReactComponent as Book } from "../../../assets/icons/book.svg";
import { ReactComponent as Zap } from "../../../assets/icons/zap.svg";
import { ReactComponent as File } from "../../../assets/icons/file.svg";
import { ReactComponent as LifeBuoy } from "../../../assets/icons/life-buoy.svg";
import { ReactComponent as Codepen } from "../../../assets/icons/codepen.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="flex w-full py-6 items-center">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex flex-col lg:flex-row  w-full lg:items-center justify-start lg:gap-8">
          <div className="flex-none flex items-center w-full lg:w-auto justify-between">
            <Link to="/" className="inline-flex">
              <Logo />
            </Link>
            {menuOpen === false ? (
              <div
                className="inline-flex flex-none lg:hidden cursor-pointer"
                onClick={openMenu}
                aria-hidden="true"
              >
                <MenuIcon />
              </div>
            ) : (
              <div
                className="inline-flex flex-none lg:hidden cursor-pointer"
                onClick={closeMenu}
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  onClick={closeMenu}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            )}
          </div>
          <div
            className={`${
              menuOpen === false
                ? "lg:h-auto h-0 overflow-y-hidden lg:overflow-visible"
                : "h-auto mt-6 lg:mt-0"
            } grow flex-col lg:flex-row justify-between gap-8 lg:flex transition-all duration-400`}
          >
            <div className="flex flex-col lg:flex-row lg:items-center gap-8">
              <Link
                to="/"
                className="font-medium text-base hover:text-primary-1 text-gray-5 flex gap-1 items-center justify-between"
              >
                Home
              </Link>
              <Popover className="relative w-full">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`${
                        open ? "text-primary-1" : "text-gray-5"
                      } font-medium text-base flex gap-1 items-center justify-between w-full`}
                    >
                      Products{" "}
                      <span className="inline-flex flex-none items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`${open ? "rotate-180" : ""} h-4 w-4`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="sm:absolute lg:left-1/2 z-10 mt-3 w-full sm:w-screen max-w-sm lg:-translate-x-1/2 transform sm:px-4 lg:max-w-[640px]">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                          <div className="relative grid grid-cols-1 gap-8 bg-white p-4 lg:grid-cols-2">
                            <div>
                              <h6 className="font-medium text-primary-1 text-sm">
                                Products
                              </h6>
                              <div className="flex flex-col gap-8 w-full mt-4 px-2">
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-1">
                                    <BarChart />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Survey Platform
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Learn about your users.
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-1">
                                    <MonitorIcon />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Audience
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Monitor your metrics.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h6 className="font-medium text-primary-1 text-sm">
                                Use cases
                              </h6>
                              <div className="flex flex-col gap-8 w-full mt-4 px-2">
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-1">
                                    <CheckCircle />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Customer Feedback
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Analyze conversion rates.
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-2">
                                    <UserCheck />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Employee Feedback
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Measure active usage.
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-3">
                                    <Smile />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Market Research
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Find retention drivers.
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <span className="inline-flex flex-none items-center justify-center h-12 w-12 rounded-[50%] bg-accent-4">
                                    <ThreeLayers />
                                  </span>
                                  <div className="flex flex-col">
                                    <h5 className="text-primary-8 text-base font-medium">
                                      Product Research
                                    </h5>
                                    <p className="text-gray-5 font-normal text-sm">
                                      Grow your user base faster.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-white p-4 w-full">
                            <div className="bg-accent-1 grid grid-cols-1 lg:grid-cols-2 py-4 px-6 rounded-md">
                              <a
                                href="https://dashboard.surveylake.com/signup"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 text-primary-1 font-medium text-base"
                              >
                                Sign up for free
                              </a>
                              <div className="flex flex-col lg:flex-row gap-2 lg:gap-4">
                                <a
                                  href="##"
                                  className="rounded-md py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 text-primary-1 font-medium text-base flex flex-none items-center gap-1"
                                >
                                  <span>
                                    <PlayCircle />
                                  </span>
                                  <span>Watch demo</span>
                                </a>
                                <a
                                  href="##"
                                  className="rounded-md py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 text-primary-1 font-medium text-base flex items-center gap-1"
                                >
                                  <span>
                                    <MessageCircle />
                                  </span>
                                  <span>Chat to sales</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`${
                        open ? "text-primary-1" : "text-gray-5"
                      } font-medium text-base flex gap-1 items-center justify-between w-full`}
                    >
                      Resources{" "}
                      <span className="inline-flex flex-none items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`${open ? "rotate-180" : ""} h-4 w-4`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="sm:absolute lg:left-1/2 z-10 mt-3 w-full sm:w-screen max-w-sm lg:-translate-x-1/2 transform sm:px-4 lg:max-w-[792px]">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                          <div className="p-6">
                            <h5 className="text-base font-medium text-black">
                              Resources
                            </h5>
                            <h6 className="font-normal text-sm text-gray-5">
                              Get started and learn more about our products.
                            </h6>
                            <div className="grid grid-cols-1 lg:grid-cols-3 mt-8 gap-4">
                              <div>
                                <h5 className="text-sm font-medium text-primary-1">
                                  Get started
                                </h5>
                                <ul className="flex flex-col gap-4 mt-4">
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/setup">Setup 101</Link>
                                  </li>
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/add">Adding users</Link>
                                  </li>
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/tutorials">Video tutorials</Link>
                                  </li>
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/templates">Using templates</Link>
                                  </li>
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/survey">
                                      Survey distribution
                                    </Link>
                                  </li>
                                  <li className="text-base font-medium text-gray-5">
                                    <Link to="/dashboard">Dashboard</Link>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-primary-1">
                                  Resources
                                </h5>
                                <div className="flex flex-col mt-4">
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <Book />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        Blog
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        The latest industry news, updates and
                                        info.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <Zap />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        Templates
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        Learn how our customers are making big
                                        changes.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <PlayCircle />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        Sample Calculator
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        Get up and running on new features and
                                        techniques.
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-primary-1">
                                  Support
                                </h5>
                                <div className="flex flex-col mt-4">
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <File />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        Documentation
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        All the boring stuff that you (hopefully
                                        won’t) need.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <LifeBuoy />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        Help and support
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        Learn, fix a problem, and get quick
                                        answers.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="flex gap-4 items-start mb-8">
                                    <span className="inline-flex flex-none justify-center items-center">
                                      <Codepen />
                                    </span>
                                    <div>
                                      <h5 className="font-medium text-base text-black">
                                        API reference
                                      </h5>
                                      <h6 className="font-normal text-sm text-gray-5 mt-2">
                                        Complete reference documentation for
                                        API.
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <Link
                to="/pricing"
                className="font-medium text-base hover:text-primary-1 text-gray-5 flex gap-1 items-center justify-between"
              >
                Pricing
              </Link>
            </div>
            <div className="flex flex-col-reverse sm:flex-row sm:gap-8 gap-2 items-center mt-6 lg:mt-0">
              <a
                href="https://dashboard.surveylake.com/login"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-base bg-accent-1 sm:bg-transparent text-primary-1 hover:text-primary-1 py-[10px] sm:text-gray-5 sm:w-auto w-full text-center rounded-lg"
              >
                Login
              </a>
              <a
                href="https://dashboard.surveylake.com/signup"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-base text-white hover:text-white bg-primary-1 rounded-lg py-[10px] px-[18px] w-full text-center sm:w-auto"
              >
                Signup
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
