import api from "./api";

export async function addWaitlistEmail({ email }) {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/join/waitlist`;
  return api.post(url, { email });
}

export async function addContactMessage({
  name,
  email,
  accountType,
  subject,
  message,
}) {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/contact-us`;
  return api.post(url, {
    name,
    email,
    account_type: accountType,
    subject,
    message,
  });
}

export async function getTemplates() {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/templates`;
  return api.get(url);
}

export async function getOneTemplate({ templateId }) {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/templates/${templateId}`;
  return api.get(url);
}
