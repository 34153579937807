import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Loader } from "./components/common";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/CTAs/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Help = lazy(() => import("./pages/CTAs/Help"));
const Pricing = lazy(() => import("./pages/CTAs/Pricing"));
const SampleCalculator = lazy(() => import("./pages/CTAs/Sample-calculator"));
const Faq = lazy(() => import("./pages/CTAs/Faq"));
const Audience = lazy(() => import("./pages/CTAs/Audience"));
const PrivacyPolicy = lazy(() => import("./pages/CTAs/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./pages/CTAs/CookiePolicy"));
const Templates = lazy(() => import("./pages/Templates"));
const Waitlist = lazy(() => import("./pages/Waitlist"));
const Blog = lazy(() => import("./pages/CTAs/Blog"));
const NotFound = lazy(() => import("./pages/CTAs/NotFound"));
const EmployeeFeedback = lazy(() => import("./pages/CTAs/EmployeeFeedback"));
const CustomerFeedback = lazy(() => import("./pages/CTAs/CustomerFeedback"));
const MarketResearch = lazy(() => import("./pages/CTAs/MarketResearch"));
const TemplateView = lazy(() => import("./pages/TemplateView"));

const CustomerSurvey = lazy(() =>
  import("./pages/CTAs/Templates/CustomerSurvey")
);
const EmployeeSurvey = lazy(() =>
  import("./pages/CTAs/Templates/EmployeeSurvey")
);
const MarketSurvey = lazy(() => import("./pages/CTAs/Templates/MarketSurvey"));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader isLoading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/help" element={<Help />} />
        <Route path="/sample-calculator" element={<SampleCalculator />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/t/:templateId/preview" element={<TemplateView />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/audience" element={<Audience />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/employee-feedback" element={<EmployeeFeedback />} />
        <Route path="/customer-feedback" element={<CustomerFeedback />} />
        <Route path="/customer-survey" element={<CustomerSurvey />} />
        <Route path="/employee-survey" element={<EmployeeSurvey />} />
        <Route path="/market-research" element={<MarketResearch />} />
        <Route path="/market-survey" element={<MarketSurvey />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
