const Skeleton = () => (
  <div className="animate-pulse flex flex-col rounded-[5px] shadow-lg pb-6">
    <div className="bg-gray-1 h-[160px]" />
    <div className="flex-1 space-y-6 py-1 px-4 mt-8">
      <div className="h-2 bg-gray-1 rounded" />
      <div className="space-y-3">
        <div className="grid grid-cols-3 gap-4">
          <div className="h-2 bg-gray-1 rounded col-span-2" />
          <div className="h-2 bg-gray-1 rounded col-span-1" />
        </div>
        <div className="h-2 bg-gray-1 rounded" />
        <div className="space-y-6">
          <div className="h-2 bg-gray-1 rounded mt-6" />
        </div>
      </div>
    </div>
  </div>
);

export default Skeleton;
