import Portal from "@reach/portal";
import loader from "../../../assets/images/loader.png";

const Loader = ({ isLoading = false }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Portal>
      <div className="h-screen w-screen fixed flex items-center justify-center top-0 bg-[rgba(0,0,0,0.5)]">
        <img
          src={loader}
          alt="loader"
          className="w-[70px] animate-spin absolute"
        />
      </div>
    </Portal>
  );
};

export default Loader;
