const Button = ({ children, type, className, variant, disabled, ...rest }) => {
  const variants = {
    primary: "bg-primary-1 text-white border-0",
    warning: "bg-primary-2 text-gray-4 border-0",
    black: "bg-gray-4 text-white border-0",
    "outline-primary":
      "border border-primary-1 text-primary-1 hover:text-primary-1",
    white: "bg-white text-primary-1 border-0",
  };

  return (
    <button
      type={type}
      {...rest}
      disabled={disabled}
      className={`flex items-center justify-center border-solid py-[10px] px-6 rounded-[10px] no-underline md:px-4 md:py-[6px] lg:py-[10px] lg:px-6 ${className} ${variants[variant]}`}
    >
      {children}
    </button>
  );
};

export default Button;
