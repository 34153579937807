import axios from "axios";

/**
 * Defines an axios instance
 */
const api = axios.create({
  baseURL: "https://api.airtable.com/v0",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
