import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/tick-square.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/close-square.svg";

const alertRoot = document.getElementById("alert");

const AlertPortal = ({
  statusType = "success",
  message,
  onRemove = () => {},
}) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    alertRoot.appendChild(elRef.current);
  }, []);

  // To auto remove
  useEffect(() => {
    const timer = setTimeout(() => {
      alertRoot.removeChild(elRef.current);
      // clear has error state
      onRemove();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return createPortal(
    <div
      className="z-[1] fixed flex items-center bg-primary-3 rounded-[10px] text-white py-[18px] px-6 top-[5px] w-[96%] left-[2%] md:w-[400px] md:left-[calc(60%-200px)]"
      style={{
        backgroundColor: statusType === "success" ? "#08AF73" : "#FF5645",
      }}
    >
      {statusType === "success" ? <SuccessIcon /> : <ErrorIcon />}
      <div className="ml-[14px] text-center flex-auto">
        <p className="text-white">{message}</p>
      </div>
    </div>,
    elRef.current
  );
};

const Alert = ({ statusType = "success", message, onRemove = () => {} }) => {
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      {counter > 0 && (
        <AlertPortal
          statusType={statusType}
          message={message}
          onRemove={onRemove}
        />
      )}
    </>
  );
};

export default Alert;
