import { Link } from "react-router-dom";
import { ReactComponent as LogoAlt } from "../../../assets/images/logo-alt.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/socials/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/socials/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/socials/linkedin.svg";

const DarkFooter = () => (
  <footer className="relative mt-36">
    <section className="bg-primary-7 pt-64 sm:pt-36">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col sm:flex-row gap-8 sm:gap-24 my-16">
          <div className="flex flex-col max-w-xs">
            <div className="inline-flex flex-none items-center">
              <LogoAlt />
            </div>
            <p className="text-base font-normal text-gray-10 mt-4">
              Easily create surveys and polls to collect customer feedback, and
              measure employee satisfaction
            </p>
          </div>
          <div className="grid justify-between grow w-full gap-y-8 grid-cols-2 sm:grid-cols-3 xl:grid-cols-5">
            <div className="flex flex-col">
              <h6 className="text-sm font-semibold text-gray-9">Product</h6>
              <ul className="flex flex-col gap-4 mt-4">
                <li>
                  <Link
                    to="/overview"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Survey Platform
                  </Link>
                </li>
                <li>
                  <Link
                    to="/audience"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Audiences
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pricing"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col">
              <h6 className="text-sm font-semibold text-gray-9">Company</h6>
              <ul className="flex flex-col gap-4 mt-4">
                <li>
                  <Link
                    to="/about"
                    className="text-gray-10 text-base font-semibold"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/careers"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/press"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Press
                  </Link>
                </li>
                <li>
                  <Link
                    to="/news"
                    className="text-gray-10 text-base font-semibold"
                  >
                    News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/media"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Media kit
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col">
              <h6 className="text-sm font-semibold text-gray-9">Resources</h6>
              <ul className="flex flex-col gap-4 mt-4">
                <li>
                  <Link
                    to="/blog"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/newsletter"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Newsletter
                  </Link>
                </li>
                <li>
                  <Link
                    to="/templates"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Templates
                  </Link>
                </li>
                <li>
                  <Link
                    to="/help"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Help centre
                  </Link>
                </li>
                <li>
                  <Link
                    to="/tutorials"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Tutorials
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col">
              <h6 className="text-sm font-semibold text-gray-9">Social</h6>
              <ul className="flex flex-col gap-4 mt-4">
                <li>
                  <Link
                    to="/overview"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Twitter
                  </Link>
                </li>
                <li>
                  <Link
                    to="/features"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Linkedin
                  </Link>
                </li>
                <li>
                  <Link
                    to="/solutions"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Facebook
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col">
              <h6 className="text-sm font-semibold text-gray-9">Legal</h6>
              <ul className="flex flex-col gap-4 mt-4">
                <li>
                  <Link
                    to="/terms"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Terms
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookies"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Cookies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/licenses"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Licenses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings"
                    className="text-gray-10 text-base font-semibold"
                  >
                    Settings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-primary-8 mt-16 py-8">
        <div className="container mx-auto px-4 md:px-8">
          <div className="flex flex-col-reverse gap-4 justify-between sm:flex-row">
            <div className="flex">
              <p className="text-gray-9 text-base font-normal">
                &copy; {new Date(Date.now()).getFullYear()} Surveylake. All
                rights reserved.
              </p>
            </div>
            <div className="flex gap-4">
              <div className="inline-flex h-6 w-6 items-center justify-center">
                <TwitterIcon />
              </div>
              <div className="inline-flex h-6 w-6 items-center justify-center">
                <LinkedinIcon />
              </div>
              <div className="inline-flex h-6 w-6 items-center justify-center">
                <FacebookIcon />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </footer>
);

export default DarkFooter;
